<template>
  <!-- BOX FOR CUSTOMIZED FILTERS -->
  <div
    :class="{
      'filter-box elevation-1': true,
      disabled: disabled,
    }"
    :data-test-id="'filter_box_' + filter.property"
  >
    <v-card
      :id="'label_' + _uid"
      flat
      class="filter-box-label"
      color="psgreen"
      dark
      :tabindex="disabled ? -1 : 0"
      @keydown="handleKey"
      :data-test-id="'filter_' + filter.property + '_label'"
    >
      <div class="filter-box-title text-truncate" :title="filter.text">
        {{ filter.text }}
      </div>
    </v-card>
    <v-card
      flat
      v-if="operatorOptions"
      class="filter-box-select-wrapper"
      ref="selectWrapper"
    >
      <span class="operator-select-spreader" ref="selectSpreader"></span>
      <select
        v-model="operator"
        @change="resizeInputs"
        class="filter-box-select"
        ref="select"
        data-test-id="filterOperatorSelector"
        :disabled="disabled"
      >
        <option
          v-for="(option, index) in operatorOptions"
          :value="option.value"
          :key="index"
          :data-test-id="'operator_' + option.value"
        >
          {{ option.text }}
        </option>
      </select>
    </v-card>
    <!-- BOOLEAN -->
    <v-card
      v-if="filter.type === 'boolean'"
      flat
      class="filter-box-input-wrapper"
    >
      <select
        tabindex="0"
        ref="input"
        v-model="value"
        class="filter-box-input-field"
        @keydown="handleKey"
        :data-test-id="'filter_' + filter.property + '_boolean_input'"
        :disabled="disabled"
      >
        <option
          value="true"
          :data-test-id="'filter_' + filter.property + '_boolean_true'"
        >
          True
        </option>
        <option
          value="false"
          :data-test-id="'filter_' + filter.property + '_boolean_false'"
        >
          False
        </option>
      </select>
    </v-card>
    <!-- DATE -->
    <v-card
      v-else-if="filter.type === 'date'"
      flat
      :class="{
        'filter-box-input-wrapper': true,
        date: filter.dateOnly,
        'date-time': !filter.dateOnly,
      }"
    >
      <span class="input-spreader" ref="inputSpreader"></span>
      <input
        v-model="value"
        tabindex="0"
        ref="input"
        class="filter-box-input-field"
        step="1"
        :type="filter.dateOnly ? 'date' : 'datetime-local'"
        :data-test-id="'filter_' + filter.property + '_date_input'"
        :disabled="disabled"
        @keydown="handleKey"
      />
    </v-card>
    <!-- LIST -->
    <v-card
      v-else-if="filter.type === 'list'"
      flat
      class="filter-box-input-wrapper"
    >
      <span class="input-spreader" ref="inputSpreader"></span>
      <select
        tabindex="0"
        ref="input"
        v-model="value"
        class="filter-box-input-field"
        @keydown="handleKey"
        :data-test-id="'filter_' + filter.property + '_list_input'"
        :disabled="disabled"
      >
        <option
          v-for="(option, index) in filter.options"
          :key="index"
          :value="option.value"
          :data-test-id="'filter_' + filter.property + '_list_' + option.value"
        >
          {{ option.text }}
        </option>
      </select>
    </v-card>
    <!-- CURRENCY -->
    <v-card
      v-else-if="filter.type === 'currency'"
      flat
      class="filter-box-input-wrapper"
    >
      <!-- use span for resizeable input field -->
      <span class="input-spreader number" ref="inputSpreader"></span>
      <input
        spellcheck="false"
        tabindex="0"
        type="number"
        ref="input"
        v-model="value"
        class="filter-box-input-field"
        @keydown="handleKey"
        :data-test-id="'filter_' + filter.property + '_currency_input'"
        :disabled="disabled"
      />
    </v-card>
    <!-- NUMBER & TEXT -->
    <v-card v-else flat class="filter-box-input-wrapper">
      <!-- use span for resizeable input field -->
      <span :class="'input-spreader ' + inputType" ref="inputSpreader"></span>
      <input
        v-model="value"
        spellcheck="false"
        tabindex="0"
        ref="input"
        class="filter-box-input-field"
        :type="inputType"
        :data-test-id="'filter_' + filter.property + '_text_input'"
        :disabled="disabled"
        @keydown="handleKey"
      />
    </v-card>
    <!-- TODO: Disable until a solution is found for currency filter
            
        <v-card v-if="filter.type === 'currency'" flat class="currency-input-wrapper">
            <select 
                tabindex="0"
                ref="currency"
                class="filter-box-input-field"
                @change="setFilterValue"
                :data-test-id="'filter_' + filter.property + '_currency_select'"
            >
                <option 
                    v-for="(currency, index) in $getCurrencies()" 
                    :key="index" 
                    :value="index"
                    :selected="index == 'EUR'"
                    :data-test-id="'filter_' + filter.property + '_currency_' + index"
                >
                    {{index}}
                </option>
            </select>
        </v-card>

        -->
  </div>
</template>

<script>
import vuetify from "@/plugins/vuetify";

export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },

  vuetify,

  data() {
    return {
      operator: null,
      value: this.filter?.value ?? "",
      selectWidth: 0,
      timeout: 0,
      disabled: false,
    };
  },

  watch: {
    value() {
      this.setFilterValue();
    },
  },

  mounted() {
    this.operator =
      this.filter?.operator ?? this.operatorOptions?.[0]?.value ?? "eq";
    this.$nextTick(() => {
      this.resizeInputs();
      const spreader = this.$refs.inputSpreader;
      if (spreader && this.filter.type === "date") {
        spreader.innerText = this.datePlaceholder;
      }
    });
  },

  methods: {
    setFilterValue() {
      if (this.$refs.input.validity.badInput) return;
      //emit changed filter event 300ms after the last input change
      window.clearTimeout(this.timeout);
      //set current value to spreader to let the input field grow with the text
      const spreader = this.$refs.inputSpreader;
      if (spreader) {
        spreader.innerText =
          this.filter.type === "date" ? this.datePlaceholder : this.value;
      }
      this.timeout = window.setTimeout(() => {
        const currency = this.$refs.currency ? this.$refs.currency.value : null;
        let value = this.value;

        if (value && typeof value === "string") {
          value = value.trim();
        }

        if (currency && this.filter.type === "currency") {
          value = this.$parsePriceToFractionUnit(value, currency);
        }

        if (this.filter.type === "number") {
          value = Number(value);
        }

        if (this.filter.type === "date" && this.filter.parseToUTC && value) {
          //parse date to UTC
          value = new Date(value).toISOString();
        }

        const filter = {
          id: this._uid,
          type: this.filter.type,
          property: this.filter.property,
          value,
          operator: this.operator,
        };
        this.$emit("update-filters", filter);
      }, 300);
    },

    handleKey(event) {
      if (this.disabled) return;
      const key = event.keyCode || event.charCode;
      const el = event.target || this.$refs.input;
      const type = el.type;
      if (key == 8 /* backspace key */) {
        if (
          (el && el.id == "label_" + this._uid) ||
          (type == "text" && el.selectionStart == 0 && el.selectionEnd == 0) ||
          (type == "number" && !el.value && !el.validity.badInput) ||
          type == "select-one"
        ) {
          event.preventDefault();
          event.stopPropagation();
          this.$emit("remove-box", {
            id: this._uid,
            property: this.filter.property,
            value: null,
          });
        }
      }
    },

    resizeInputs() {
      if (this.value) {
        this.$refs.inputSpreader.innerHTML = this.value;
      }

      const select = this.$refs.select;
      if (select) {
        this.$refs.selectSpreader.innerHTML =
          select?.selectedOptions?.[0]?.innerText;
        this.setFilterValue();
      }
    },
  },

  computed: {
    inputType() {
      return this.filter.type === "text" ? "text" : "number";
    },

    operatorOptions() {
      const operatorOptions = this.filter?.operatorOptions;
      if (operatorOptions) return operatorOptions;
      const type = this.filter.type;
      switch (type) {
        case "text":
        case "boolean":
        case "list":
          return null;
        case "date":
          return [
            { text: "is", value: "eq" },
            { text: "before", value: "lt" },
            { text: "after", value: "gt" },
          ];

        default:
          //number
          return [
            { text: "is", value: "eq" },
            { text: "is less than", value: "lt" },
            { text: "is less than or equal to", value: "lte" },
            { text: "is greater than", value: "gt" },
            { text: "is greater than or equal to", value: "gte" },
          ];
      }
    },

    datePlaceholder() {
      //Add dots as placeholder for the date/date-time input
      //so the inputs value is shown fully
      return new Array(this.filter.dateOnly ? 24 : 41).join(".");
    },
  },
};
</script>

<style scoped>
.filter-box {
  max-height: 40px;
  display: flex;
  flex-flow: row nowrap;
  margin: 3px 5px 3px 5px;
  min-width: fit-content;
  font-size: 18px;
  box-sizing: border-box;
  border: 1px solid var(--v-psgreen-base);
}

.filter-box.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.filter-box:focus-within {
  outline: none;
  border-color: rgb(46, 117, 212);
  box-shadow: rgb(132 185 245) 0px 0px 0px 2px;
}

.filter-box-input-wrapper,
.currency-input-wrapper {
  display: inline-block;
  position: relative;
  margin: 0px;
  padding: 0px;
  min-width: 8ch;
  min-height: 38px;
  border-radius: 0;
}

.filter-box-input-wrapper.date-time {
  min-width: 154px;
}

.currency-input-wrapper {
  min-width: 0;
  width: 5ch;
}

.filter-box-select-wrapper {
  display: flex;
  min-width: fit-content;
  background: linear-gradient(90deg, var(--v-psgreen-base) 50%, #ffff 50%);
  border-radius: 0;
}

.filter-box-select {
  width: 100%;
  font-size: 0.75rem;
  padding: 3px 6px 3px 6px;
  align-self: center;
  height: fit-content;
  background: white;
  border: 1px solid var(--v-psgreen-base);
  border-radius: 10em !important;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.filter-box-input-field {
  width: 100%;
  height: 100%;
  min-height: 38px;
  outline: none;
  text-align: left;
  position: absolute;
  left: 0;
  bottom: 0;
  margin-left: 5px;
  padding-right: 10px;
}

.operator-select-spreader,
.input-spreader {
  position: relative;
  white-space: nowrap;
  color: transparent;
  background-color: transparent;
  vertical-align: middle;
}

.input-spreader {
  left: 0;
  margin: 0px 10px 0px 5px;
}

.input-spreader.number {
  margin: 0px 10px 0px 20px;
}

.operator-select-spreader {
  font-size: 0.75rem;
  padding: 3px 7px 3px 7px;
  text-align: center;
}

@supports (-moz-appearance: none) {
  .operator-select-spreader {
    font-size: 0.75rem;
    padding: 3px 14px 3px 7px;
    text-align: center;
  }
}

.filter-box-label {
  display: flex;
  height: 38px;
  min-width: 8ch;
  padding: 0px 4px 0px 4px;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  cursor: pointer;
}
</style>