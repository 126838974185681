<template>
  <div class="toolbar elevation-4">
    <div flat :height="80" class="search-toolbar">
      <div class="search-bar-container">
        <SearchBar
          v-model="filterQuery"
          v-bind="internalSearchProps"
          :disabled="disabled"
          @update-filters="$emit('update-filters', $event)"
        />
      </div>
      <slot name="actions" :disabled="disabled" />
    </div>
    <div v-show="hasExtendedSlot">
      <slot name="extended" :disabled="disabled" />
    </div>
  </div>
</template>

<script>
import SearchBar from "../../search/SearchBar";

export default {
  components: {
    SearchBar,
  },

  props: {
    value: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    searchProps: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      filterQuery: this.value,
    };
  },

  watch: {
    filterQuery: {
      handler: function (query) {
        this.$emit("input", query);
      },
      deep: true,
    },
  },

  computed: {
    hasExtendedSlot() {
      return this.$slots.extended;
    },

    internalSearchProps() {
      let props = this.searchProps;
      if (this.disabled) this.$set(props, "disabled", this.disabled);
      return props;
    },
  },
};
</script>

<style scoped>
.toolbar {
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
  position: relative;
  padding: 0 12px;
}

.toolbar > .search-toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 4px;
  height: 80px;
  overflow: visible;
}

.toolbar > .search-toolbar > .search-bar-container {
  display: flex;
  position: relative;
  height: 54px;
  width: 100%;
}
</style>