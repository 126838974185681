<template>
  <v-menu
    class="search-bar-filter-menu"
    v-model="openMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-container
        fluid
        :class="{
          'search-bar': true,
          'has-hidden-filters': hiddenFilterCount > 0,
        }"
      >
        <!-- SEARCH BAR -->
        <v-container
          v-resize="onResize"
          ref="container"
          class="search-bar-filter-box"
          @focusout="handleFocusOut"
        >
          <input
            v-show="!disableFullTextSearch"
            v-model="ftsQuery"
            type="text"
            spellcheck="false"
            placeholder="Type to Search..."
            class="search-bar-input"
            ref="searchBarInput"
            data-test-id="fullTextSearchInput"
            @keydown="handleKey"
          />
          <v-spacer
            v-if="disableFullTextSearch"
            class="search-bar-input"
            @click="openMenu = !disabled && !openMenu && !disableFilterCreation"
          />
        </v-container>
        <v-container class="search-bar-filter-btn-container">
          <div v-if="hiddenFilterCount > 0" class="hidden-filter-count">
            <div class="text-no-wrap text--secondary font-italic">
              + {{ hiddenFilterCount }} more
            </div>
          </div>
          <v-btn
            v-if="isFilterCreationPossible"
            v-on="on"
            v-bind="attrs"
            class="search-bar-filter-btn"
            color="primary"
            text
            data-test-id="addFilterBtn"
            :disabled="disabled"
            @mousedown.prevent
          >
            <v-icon left> mdi-filter-plus</v-icon>
            Filter
          </v-btn>
        </v-container>
      </v-container>
    </template>
    <v-card>
      <div class="d-flex flex-column elevation-4">
        <v-text-field
          v-if="possibleFilters.length > 0"
          dense
          outlined
          hide-details="auto"
          v-model="filterSearch"
          prepend-icon="mdi-magnify"
          placeholder="Type to search for filters"
          class="pa-3"
          data-test-id="filterMenuSearch"
        />
        <v-divider v-if="possibleFilters.length > 0" />
      </div>
      <!-- ALLOWED FILTERS LIST -->
      <v-subheader
        class="flex-grow-1 justify-center"
        v-if="displayedFilters.length === 0"
      >
        {{ noFiltersText }}
      </v-subheader>
      <v-list v-else class="search-bar-filter-list" dense>
        <v-list-item-group>
          <v-list-item
            v-for="(filter, i) in displayedFilters"
            :key="i"
            :class="{
              'filter-disabled': isDisabled(filter),
            }"
            @click="addFilterBox(filter)"
          >
            <v-list-item-icon>
              <v-icon v-text="getIcon(filter.type)" />
            </v-list-item-icon>
            <v-list-item-title
              :data-test-id="'filter_' + filter.property"
              class="d-flex flex-row align-center"
            >
              <div
                class="text-truncate"
                :title="filter.text ? filter.text : filter.property"
              >
                {{ filter.text ? filter.text : filter.property }}
              </div>
              <v-spacer />
              <v-chip
                class="ml-3"
                :ripple="false"
                small
                dark
                label
                color="psgreen"
              >
                {{ filter.type.toUpperCase() }}
              </v-chip>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import FilterBox from "./FilterBox";
import Vue from "vue";

const noFiltersText = "No filter matches the search";

export default {
  props: {
    value: {
      type: Object,
      required: false,
    },

    possibleFilters: {
      type: Array,
      required: true,
    },

    disableFullTextSearch: {
      type: Boolean,
      required: false,
      default: false,
    },

    //This flag defines, if the more advance "query_string"
    //should be used for the full text search instead of "simple_query_string"
    advancedSearch: {
      type: Boolean,
      required: false,
      default: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    disableFilterCreation: {
      type: Boolean,
      required: false,
      default: false,
    },

    disableFilterDeletion: {
      type: Boolean,
      required: false,
      default: false,
    },

    uniqueFilters: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      timeout: 0,
      filters: {},
      openMenu: false,
      ftsQuery: "",
      filterSearch: "",
      noFiltersText,
      filterBoxes: [],
      searchBarWidth: 0,
      reloadHiddenFilters: 0,
    };
  },
  mounted() {
    const query = this.value;
    if (query) this.parseToFilter(query);
    this.$nextTick(() => {
      //Add resize oberserver
      const element = this.$refs.container;
      new ResizeObserver(this.onResize).observe(element);
    });
  },

  watch: {
    ftsQuery(value) {
      const dom = this.$refs.searchBarInput;
      if (dom === document.activeElement) {
        //emit changed filter event 300ms after the last input change
        window.clearTimeout(this.timeout);
        this.timeout = window.setTimeout(() => {
          this.setSearchValue(value, true);
        }, 300);
      } else {
        this.setSearchValue(value);
      }
    },
  },

  methods: {
    onResize() {
      const element = this.$refs.container;
      this.searchBarWidth = element?.offsetWidth ?? 0;
    },

    handleKey(event) {
      const key = event.keyCode || event.charCode;
      if (key === 8) {
        //if the pressed key is backspace, check if a filter box has to be removed
        const el = event.target;
        if (el && el.selectionStart === 0 && el.selectionEnd === 0) {
          const previousBox = el.previousSibling;
          if (previousBox) {
            this.removeBox(previousBox, this.$refs.container);
          }
        }
      }
    },

    removeBox(node, container) {
      //remove a filter box from the search bar
      const filterBox = node.__vue__;
      const id = filterBox._uid;
      const filter = this.filters?.[id];

      if (filter) {
        filter.value = null;
        this.updateFilters(filter, true);
      }

      const idx = this.filterBoxes.findIndex(({ _uid }) => _uid === id);
      if (idx > -1) {
        this.filterBoxes.splice(idx, 1);
      }

      filterBox.$destroy();
      container.removeChild(node);
    },

    addFilterBox(filter) {
      //Add a new filter box to the search bar

      if (Object.keys(this.filters).length === 20) {
        this.$store.commit("SET_ERROR", "Maximum of 20 filters reached!");
        return;
      }
      //create a new FilterBox component instance
      const FilterBoxClass = Vue.extend(FilterBox);
      const filterBox = new FilterBoxClass({
        parent: this,
        propsData: {
          filter,
        },
      });
      //Update the disabled status of the filterBox component
      const unwatch = this.$watch(
        "disabled",
        (disabled) => (filterBox.$data.disabled = disabled)
      );

      filterBox.$mount();
      const self = this;
      filterBox.$on("update-filters", (filter) => {
        if (filterBox.$el && filterBox.$el.contains(document.activeElement))
          self.updateFilters(filter, true);
      });

      filterBox.$on("remove-box", (filter) => {
        if (this.disableFilterDeletion) return;
        const previousFilterBox = filterBox.$el.previousSibling;

        //Focus previous filter box input
        if (previousFilterBox && previousFilterBox.querySelector("input")) {
          const inputChild = previousFilterBox.querySelector("input");
          inputChild.focus();
        } else {
          self.$refs.searchBarInput.focus();
        }

        const idx = this.filterBoxes.findIndex(
          ({ _uid }) => _uid === filterBox._uid
        );
        if (idx > -1) {
          this.filterBoxes.splice(idx, 1);
        }

        self.$refs.container.removeChild(filterBox.$el);
        filterBox.$destroy();
        unwatch(); //<-- remove the watcher
        this.updateFilters(filter, true);
      });

      //insert the filter box before the search bar and focus its input
      this.$refs.container.insertBefore(
        filterBox.$el,
        this.$refs.searchBarInput
      );

      this.filterBoxes.push(filterBox);

      this.$nextTick(() => {
        this.openMenu = false;
        filterBox.$refs.input.focus();
      });
      return filterBox;
    },

    setSearchValue(value, notify) {
      const search = {
        id: "full-text-search",
        property: "full-text-search",
        value: value,
      };
      this.updateFilters(search, notify);
    },

    /* Set the content filters */
    updateFilters(filter, notify) {
      this.reloadHiddenFilters++;
      if (!filter.id || !filter.property) return;

      const isPossibleFilter = this.possibleFilters.some(
        (possFilter) => possFilter.property === filter.property
      );

      const isValidFilter =
        filter.property === "full-text-search" || isPossibleFilter;

      if (filter.value && isValidFilter) {
        //update the filter with the new changed value
        this.$set(this.filters, filter.id, filter);
      } else {
        //if the filter has no value, remove it from the array
        this.$delete(this.filters, filter.id);
      }

      if (notify) {
        //notify parent componenent, that the filters changed
        this.parseToESQuery(this.filters);
        this.$emit("update-filters", this.filters);
      }
    },

    parseToESQuery(filters) {
      //create elastic search query from filter boxes
      const query = {
        bool: {
          filter: [],
        },
      };

      //Create the search query
      for (let index in filters) {
        const filter = filters[index];
        if (filter.property === "full-text-search") {
          //add input from full-text search
          if (filter.value) {
            query.bool.must = {
              [this.fullTextSearchType]: {
                query: filter.value,
              },
            };
          }
        } else {
          //add filter values
          let filterQuery = {};
          if (filter.value) {
            if (filter.operator !== "eq") {
              //create range filter for types like date, number,...
              filterQuery = {
                range: {
                  [filter.property]: {
                    [filter.operator]: filter.value,
                  },
                },
              };
            } else {
              //add filters which have no range (text, boolean) or should equal the input value
              //use wildcard query and keyword property for text filter
              const property =
                filter.property + (filter.type === "text" ? ".keyword" : "");
              const queryType = filter.type === "text" ? "wildcard" : "match";
              filterQuery = {
                [queryType]: {
                  [property]:
                    filter.type === "text"
                      ? { value: filter.value }
                      : filter.value,
                },
              };
            }
          }

          if (Object.keys(filterQuery).length > 0) {
            query.bool.filter.push(filterQuery);
          }
        }
      }
      this.$emit("input", query);
    },

    parseToFilter(query) {
      //create filter boxes from elastic search query
      if (query) {
        if (!query.bool) return;
        if (query.bool.must) {
          //set full text search query
          this.ftsQuery = query.bool.must[this.fullTextSearchType].query;
        }

        const queryFilters = query.bool.filter;
        //Create filter boxes
        queryFilters.forEach((queryFilter) => {
          if (queryFilter.range) {
            //create mutliple filter boxes according to the given ranges (lt, gt, lte, ...) in the ES filter object
            const range = queryFilter.range;
            for (let property in range) {
              //check if there is a possible flter for the given property
              const template = this.possibleFilters.find(
                (f) => f.property === property
              );
              if (!template) {
                this.updateFilters({});
                return;
              }
              let filter = Object.assign({}, template);
              if (filter) {
                Object.entries(range[property]).forEach((entry) => {
                  let value = entry[1];

                  //if the filter is of type date and the value is in UTC time,
                  //convert it to the users timezone
                  if (value && filter.type === "date" && value.endsWith("Z")) {
                    const date = new Date(value);
                    value = this.$formatToISO(date);
                  }

                  filter.operator = entry[0];
                  filter.value = value;
                  let filterBox = this.addFilterBox(filter);
                  filter.id = filterBox._uid;
                  this.updateFilters(filter);
                });
              }
            }
          } else {
            //create a filter box from the given elastic search filter object
            Object.values(queryFilter).forEach((type) => {
              Object.entries(type).forEach((entry) => {
                const property = entry[0].replace(".keyword", "");
                //check if there is a possible flter for the given property
                const template = this.possibleFilters.find(
                  (f) => f.property === property
                );
                if (!template) {
                  this.updateFilters({});
                  return;
                }
                let filter = Object.assign({}, template);
                if (filter.property) {
                  filter.operator = "eq";
                  let value = entry[1];

                  //if the filter is of type date and the value is in UTC time,
                  //convert it to the users timezone
                  if (value && filter.type === "date" && value.endsWith("Z")) {
                    const date = new Date(value);
                    value = this.$formatToISO(date);
                  }

                  if (value.value) {
                    filter.value = value.value;
                  } else {
                    filter.value = value;
                  }
                  let filterBox = this.addFilterBox(filter);
                  filter.id = filterBox._uid;
                  this.updateFilters(filter);
                }
              });
            });
          }
        });

        this.$emit("update-filters", this.filters);
      }
    },

    getIcon(type) {
      switch (type) {
        case "text":
          return "mdi-text";
        case "boolean":
          return "mdi-toggle-switch";
        case "date":
          return "mdi-calendar";
        case "list":
          return "mdi-format-list-numbered";
        case "currency":
          return "mdi-currency-usd";
        default:
          //number
          return "mdi-numeric";
      }
    },

    handleFocusOut() {
      this.reloadHiddenFilters++;
    },

    isDisabled({ property, isUnique = false }) {
      if (!this.uniqueFilters && !isUnique) return false;
      return this.activeFilters.some((filter) => {
        return filter.property === property;
      });
    },
  },

  computed: {
    fullTextSearchType() {
      if (this.advancedSearch) return "query_string";
      return "simple_query_string";
    },

    displayedFilters() {
      const filters = this.possibleFilters;
      const search = this.filterSearch;
      if (search) {
        //Simple wildcard search, see https://stackoverflow.com/a/32402438
        const escapeRegex = (str) => {
          //eslint-disable-next-line
          return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        };
        const searchRegex = new RegExp(
          "^" + search.split("*").map(escapeRegex).join(".*") + "$"
        );

        return filters.filter((filter) => {
          const text = filter.text ?? filter.property;
          return (
            text.toLowerCase().includes(search.toLowerCase()) ||
            searchRegex.test(text)
          );
        });
      }
      return filters;
    },

    activeFilters() {
      return Object.keys(this.filterBoxes).map((boxId) => {
        const filterBox = this.filterBoxes[boxId];
        const filter = filterBox?._props?.filter;
        return filter;
      });
    },

    hiddenFilterCount() {
      this.searchBarWidth;
      this.reloadHiddenFilters;
      const hiddenFilters = this.filterBoxes.filter((filterBox) => {
        const element = filterBox.$el;
        //if the top offset of the filter element is higher than the
        //search-bar height (44px), it is hidden when the search-bar is not focused
        return element?.offsetTop > 44;
      });

      const searchBarInput = this.$refs.searchBarInput;
      if (this.ftsQuery && searchBarInput?.offsetTop > 44) {
        //show +1 for full text search if it is hidden and has a value
        hiddenFilters.push(searchBarInput);
      }

      return hiddenFilters.length;
    },

    isFilterCreationPossible() {
      return (
        !this.disableFilterCreation &&
        this.possibleFilters &&
        this.possibleFilters.length > 0
      );
    },
  },
};
</script>

<style scoped>
.search-bar {
  display: flex;
  border: 1px solid lightgray;
  border-radius: 6px;
  padding: 0;
  z-index: 8;
  overflow: hidden;
}

.search-bar:focus-within {
  position: absolute;
  top: 0;
  width: 100%;
  border-color: rgb(46, 117, 212);
  box-shadow: rgb(132 185 245) 0px 0px 0px 3px;
  outline: none;
  height: auto;
  overflow: visible;
  background-color: white;
}

.search-bar-filter-box:focus-within {
  height: auto;
  overflow: visible;
}

.search-bar-filter-box {
  display: flex;
  flex-flow: row wrap;
  padding: 0;
  background-color: white;
  align-items: center;
  max-width: 100%;
  height: 44px;
  margin: 4px;
  position: relative;
}

.search-bar-filter-btn-container {
  display: flex;
  flex-shrink: 10;
  padding: 0;
  justify-content: space-between;
}

.search-bar-filter-btn-container > .search-bar-filter-btn {
  display: flex;
  flex: 1 1 100%;
  height: 100%;
}

input:focus {
  outline: none;
}

.search-bar-input {
  display: flex;
  flex: 1 1 auto;
  width: auto;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 3px;
  font-size: 20px;
}

.search-bar-input.spacer {
  margin-top: 0;
  margin-bottom: 0;
  padding: 20px 0;
  cursor: pointer;
}

.search-bar-filter-list {
  text-align: left;
  max-height: 300px;
  overflow-y: scroll;
}

.search-bar-filter-list .filter-disabled {
  opacity: 0.38;
  pointer-events: none;
}

.search-bar > .search-bar-filter-btn-container > .hidden-filter-count {
  display: flex;
  align-items: center;
  margin-right: 4px;
}

.search-bar:focus-within
  > .search-bar-filter-btn-container
  > .hidden-filter-count {
  display: none;
}
</style>